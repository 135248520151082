
  import Vue, { VueConstructor } from 'vue'
import permissionMixin from "@/mixins/PermissionMixin.vue"

  export default (Vue as VueConstructor<Vue & InstanceType<typeof permissionMixin>>).extend({
    name: 'Menu',
    mixins: [permissionMixin],
    data: () => ({
        tab: 'users',
    }),
    methods: {
      onChange() {
        this.$emit('change', this.tab)
      }
    }
  })
