
import Vue from "vue"
import ListParagon, { Filter } from '@/components/ListParagon.vue'
import dateFormatMixin from '@/mixins/DateFormatMixin.vue'

export default Vue.extend({
  components: {
    ListParagon
  },
  mixins: [
    dateFormatMixin
  ],
  data () {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.last_name"),
          align: 'start',
          sortable: true,
          value: 'lastname',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.first_name"),
          sortable: true,
          value: 'firstname',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.permissions"),
          sortable: true,
          value: 'permissions',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.spaces"),
          sortable: true,
          value: 'spaces',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          sortable: true,
          value: 'email',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.last_login_at"),
          sortable: true,
          value: 'last_login_at',
        },
        {
          text: this.$vuetify.lang.t("$vuetify.v3_last_login_at"),
          sortable: true,
          value: 'v3_last_login_at',
        }
      ],
      headersSplitPane: [
        {
          text: this.$vuetify.lang.t("$vuetify.last_name"),
          sortable: true,
          value: 'lastname'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.first_name"),
          sortable: true,
          value: 'firstname'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.spaces"),
          sortable: true,
          value: 'spaces'
        }
      ],
      filters: [
        {label: 'email', type: 'text-field'},
        {label: 'firstname', type: 'text-field'},
        {label: 'lastname', type: 'text-field'},
        {label: 'spaces', type: 'autocomplete', url:'/v1/agencies', text:'name', value:'id', search: 'name'},
        {label: 'last_login_at', type: 'date', allowsOnlyOneDate: true},
        {label: 'v3_last_login_at', type: 'date', allowsOnlyOneDate: true}
      ] as Array<Filter>,
      url: "/v1/space/users"
    }
  },
   methods: {
    deleteItems() {
      return (this.$refs.list as Vue & { deleteItems: () => void }).deleteItems()
    },
    sendEmail(mailto: Location) {
      window.location = mailto
    },
    phoneCall(tel: Location) {
      window.location = tel
    }
  }
})
