
  import Vue from 'vue'
  import UserList from '@/components/space/UserList.vue'

  export default Vue.extend({
    name: 'SpaceUsersAdministration',
    components: {
      UserList
    }
  })
